import React, { useState, useEffect } from 'react';
import { SidebarAppSDK, SidebarExtensionSDK } from '@contentful/app-sdk';

import { List, ListItem, Note, Button, EntryCard, TextInput, Grid, CopyButton } from '@contentful/f36-components';
import { useSDK } from '@contentful/react-apps-toolkit';
import axios from 'axios';

//axios.defaults.baseURL = 'http://localhost:3000';
axios.defaults.headers.post['Content-Type'] = 'application/json;charset=utf-8';
axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';


const CONTENT_FIELD_ID = 'title';
const WORDS_PER_MINUTE = 200;
const ENDPOINT = 'https://ux4bkcbcjz635u6ndbmpqwvnhy0pogxm.lambda-url.eu-central-1.on.aws/'

const Sidebar = () => {
  const sdk = useSDK<SidebarAppSDK>();
  const [status, setStatus] = useState<number>(999);
  const [resBody, setResBody] = useState<any>({});

  const data = { name: 'hoge' }

  const getBuildStatus = () => {
    //setStatus(988)
    //console.log("orrrrraaaaa")
    axios
      .post(ENDPOINT, data)
      .then(res => {
        setStatus(res.status);
        setResBody(res);
      })
      .catch((error) => {
        //console.log(error); //Logs a string: Error: Request failed with status code 404
        //const statusData = error.status;
        setStatus(error.response.status);
      });
  };

  const contentField = sdk.entry.fields[CONTENT_FIELD_ID];
  const [blogText, setBlogText] = useState(contentField.getValue());
  const [isShown, setShown] = useState(false);

  // Listen for onChange events and update the value
  useEffect(() => {
    sdk.window.startAutoResizer();
    const detach = contentField.onValueChanged((value) => {
      setBlogText(value);
    });
    return () => detach();
  }, [contentField]);

  // example implementation of a reading time calculator
  const readingTime = (text: string): { words: number; text: string } => {
    const wordCount = text.split(' ').length;
    const minutes = Math.ceil(wordCount / WORDS_PER_MINUTE);
    return {
      words: wordCount,
      text: `${minutes} min read`,
    };
  };

  // Calculate the metrics based on the new value
  const stats = readingTime(blogText || '');

  // Render the metrics with Forma36 components
  return (
    <>
      <Button variant="primary" onClick={()=>getBuildStatus()}>
        タイトル案ください。
      </Button>
      <EntryCard style={{ marginTop: '12px', marginBottom: '36px' }}
        contentType="ChatGPT"
        //title={resBody && JSON.stringify(resBody)}
        title={resBody && resBody.data}
        description={"response: " + status.toString()}
      />

      <Grid
            style={{ width: '100%' }}
            columns="4fr 1fr"
            rowGap="spacingM"
            columnGap="spacingM"
          >
      <Button variant="primary" onClick={()=>getBuildStatus()}>
        参照記事の要約
      </Button>
      <TextInput
        type="number"
        defaultValue="20"
      />
          </Grid>

    </>
  );
};

export default Sidebar;
